import axios from 'axios'
// import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import baseUrl from './base-url'

const instance = axios.create({
	baseURL: baseUrl,
	timeout: 1000 * 10
})

function removeLocalStorage() {
	localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
	localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

	localStorage.removeItem('userData')

	localStorage.removeItem('token')
	localStorage.removeItem('userFunctions')

	if (window.location.pathname != '/login') {
		window.location.href = '/login'
	}
}

instance.interceptors.response.use(
	response => {
		if (response && response.data.code === '02') {
			removeLocalStorage()
		}
		return response
	}, 
	error => {
		const {config, response} = error
		if (response.status === 401) {
			removeLocalStorage()
		}
		return Promise.reject(error)
	}
)

export default instance